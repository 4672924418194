<template>
  <div class="form-group">
    <label>{{ searchTerm.getLabel() }}</label>
    <multiselect
        v-model="searchTerm.value"
        :options="options"
        :multiple=true
        track-by="title"
        label="title"
        id="ajax"
        placeholder="Type to search"
        open-direction="bottom"
        :searchable="true"
        :loading="isLoading"
        :internal-search="false"
        :clear-on-select="false"
        :close-on-select="false"
        :options-limit="300"
        :limit="3"
        :max-height="600"
        :show-no-results="false"
        :hide-selected="true"
        @search-change="search"
    >
    </multiselect>
  </div>
</template>

<script>
import {debounce} from 'lodash/function'
import Multiselect from 'vue-multiselect'
import '@/assets/multiselect.css'
import ArraySearch from '@/components/search/terms/ArraySearch'

export default {
  name: 'MultiselectInput',
  components: {Multiselect},
  data() {
    return {
      options: [],
      isLoading: false
    }
  },
  methods: {
    search: debounce(function(query) {
        if (query.length > 0) {
          this.isLoading = true
          this.loadOptions(query).then((options) => {
            this.options = options
            this.isLoading = false
          }).catch(() => this.isLoading = false)
        }
      }, 300)

  },
  props: {
    searchTerm: {
      type: ArraySearch,
      required: true
    },
    loadOptions: {
      type: Function,
      required: true
    },
  }
}
</script>
