class Search {
    sort = {
        name: null,
        direction: null
    }

    sorts = {}

    filters = {}
    
    hasOrderBy () {
        return this.sort.name !== null
    }

    clear () {
       this.getFilters().forEach(filter => {
           filter.clear()
       })
    }

    getFilters() {
        return Object.getOwnPropertyNames(this.filters).map(name => {
            if (typeof this.filters[name].getSearchKey === 'function') {
                return this.filters[name]
            }
        }).filter(obj => obj !== undefined)
    }

    getDescription() {
        return this.getFilters().filter(f => f.notEmpty()).map(f => f.getDescription()).join(' | ')
    }

    getQuery(page = 1) {
        let searchObject = {
            page
        }

        if (this.hasOrderBy()) {
            searchObject['orderBy'] = this.getSortString()
        }

        this.getFilters().forEach(filter => {
            if (filter.notEmpty()) {
                searchObject[filter.getSearchKey()] = filter.getSearchValue()
            }
        })

        return searchObject
    }

    getSortString() {
        let sign = ((this.sort.direction === 'asc') ? '' : '-')
        let name = this.sorts[this.sort.name]

        return sign + name
    }
}

export default Search
