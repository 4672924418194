<template>
  <div class="form-group">
    <label>{{ searchTerm.getLabel() }}</label>
    <multiselect
        v-if="arrayOfObjects"
        v-model="searchTerm.value"
        :options="searchTerm.options"
        :multiple=true
        track-by="title"
        label="title"
    >
      <template v-if="hasCount" slot="option" slot-scope="props">
          <span>{{ props.option.title }}</span> <span class="badge bg-dark text-white">{{ props.option.count }}</span>
      </template>
    </multiselect>
    <multiselect
        v-else
        v-model="searchTerm.value"
        :options="searchTerm.options"
        :multiple=true
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import '@/assets/multiselect.css'
import ArraySearch from '@/components/search/terms/ArraySearch'

export default {
  name: 'MultiselectInput',
  components: { Multiselect },
  props: {
    searchTerm : {
      type: ArraySearch,
      required: true
    },
    arrayOfObjects: {
      type: Boolean,
      default: false
    },
    hasCount: {
      type: Boolean,
      default: false
    }
  }
}
</script>
