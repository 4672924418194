<template>
  <div class="form-group">
    <label>{{ searchTerm.getLabel() }}</label>
    <CInput
        v-if="searchTerm.term === '<>'"
        placeholder="от"
        v-model="searchTerm.firstValue"
    >
      <template #prepend>
        <CDropdown
            :caret="false"
            :togglerText="searchTerm.term"
            color="primary"
        >
          <CDropdownItem
              :key="term"
              @click="searchTerm.selectTerm(term)"
              v-for="term in ['=', '<=', '>=', '<>']"
          >{{ term }}</CDropdownItem>
        </CDropdown>
      </template>
      <template style="width: 55%" #append>
        <CInput
            placeholder="до"
            v-model="searchTerm.secondValue"
        ></CInput>
      </template>
    </CInput>
    <CInput
        v-else
        v-model="searchTerm.firstValue"
    >
      <template #prepend>
        <CDropdown
            :caret="false"
            :togglerText="searchTerm.term"
            color="primary"
        >
          <CDropdownItem
              :key="term"
              @click="searchTerm.selectTerm(term)"
              v-for="term in ['=', '<=', '>=', '<>']"
          >{{ term }}</CDropdownItem>
        </CDropdown>
      </template>
    </CInput>
  </div>
</template>

<script>
import TermSearch from '@/components/search/terms/TermSearch'

export default {
  name: 'MultitermInput',
  props: {
    searchTerm : {
      type: TermSearch,
      required: true
    }
  }
}
</script>
