class StringSearch {
    value = null

    key = null

    label = null

    constructor(key, label) {
        this.key = key
        this.label = label
    }

    getLabel() {
        return this.label
    }

    getDescription() {
        return this.label + ': ' + this.value
    }

    notEmpty() {
        return (this.value !== null) && (this.value.length > 0)
    }

    getSearchKey() {
        return this.key
    }

    getSearchValue() {
        return this.value
    }

    clear () {
        this.value = null
    }
}

export default StringSearch