import api from './api'

export default {
    categories() {
        return api.get('/categories')
    },
    categoriesSearch(data) {
        return api.post('/category/search', data)
    },
    brands() {
        return api.get('/brands')
    },
    brandsSearch(data) {
        return api.post('/brand/search', data)
    },
    genders() {
        return api.get('/genders')
    },
    years() {
        return api.get('/years')
    },
    yearsSearch(data) {
        return api.post('/year/search', data)
    },
    gendersSearch(data) {
        return api.post('/gender/search', data)
    },
    searchSizes(query) {
        return api.get('/search-sizes/' + query)
    },
    searchTypes(query) {
        return api.get('/search-types/' + query)
    }
}
