const terms = {
    '=': 'eq',
    '<=': 'lt',
    '>=': 'gt',
    '<>': 'bt'
}

class TermSearch {
    term = '='

    firstValue = null

    secondValue = null

    key = null

    label = null

    constructor(key, label) {
        this.key = key
        this.label = label
    }

    getLabel() {
        return this.label
    }

    getDescription() {
        if (this.term === '<>') {
            return this.label + ' ' + this.term + ' ' + this.firstValue + ', ' + this.secondValue
        } else {
            return this.label + ' ' + this.term + ' ' + this.firstValue
        }
    }

    selectTerm(term) {
        this.term = term
    }

    notEmpty () {
       return  (this.firstValue !== null) && (this.firstValue.length > 0) && terms[this.term]
    }

    termValue () {
        return terms[this.term]
    }

    getSearchValue () {
        return [this.termValue(), this.firstValue, this.secondValue]
    }

    getSearchKey () {
        return this.key
    }

    clear () {
        this.firstValue = null
        this.secondValue = null
    }
}

export default TermSearch