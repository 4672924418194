class ArraySearch {
    value = null

    key = null

    valueField = null

    label = null

    options = []

    constructor(key, label, valueField = null) {
        this.key = key
        this.valueField = valueField
        this.label = label
    }

    notEmpty() {
        return Array.isArray(this.value) && (this.value.length > 0)
    }

    getLabel() {
        return this.label
    }

    getDescription() {
        if (Array.isArray(this.value)) {
            if (this.valueField === null) {
                return this.label + ': ' + this.value.join(', ')
            } else {
                return this.label + ': ' + this.value.map(val => val['title']).join(', ')
            }
        }
        return null
    }

    getSearchValue() {
        if (this.valueField === null) {
            return this.value
        } else {
            return this.value.map(val => val[this.valueField])
        }
    }

    getSearchKey() {
        return this.key
    }

    clear() {
        this.value = null
    }
}

export default ArraySearch