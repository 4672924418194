<template>
  <CInput
      :label="searchTerm.getLabel()"
      v-model="searchTerm.value"
  />
</template>

<script>
import StringSearch from '@/components/search/terms/StringSearch'

export default {
  name: 'StringInput',
  methods: {
  },
  props: {
    searchTerm : {
      type: StringSearch,
      required: true
    }
  }
}
</script>
