const termTitles = {
    'eq': 'по точному',
    'sw': 'начинается',
    'ew': 'заканчивается',
    'ct': 'содержит',
}

class LikeSearch {
    term = 'eq'

    value = null

    key = null

    label = null

    constructor(key, label) {
        this.key = key
        this.label = label
    }

    getLabel() {
        return this.label
    }

    getDescription() {
        return this.label + ': ' + this.value + ' (' + this.termTitle(this.term) + ')'
    }

    termTitle(term) {
        return termTitles[term]
    }

    selectTerm(term) {
        this.term = term
    }

    notEmpty() {
        return this.value && (this.value.length > 0)
    }

    getSearchValue() {
        return [this.term, this.value]
    }

    getSearchKey() {
        return this.key
    }

    clear () {
        this.value = null
    }
}

export default LikeSearch