<template>
  <div class="form-group">
    <label>{{ searchTerm.getLabel() }}</label>
    <flat-pickr class="form-control" v-model="searchTerm.value" style="background-color: white" :config="rangeConfig">
    </flat-pickr>
  </div>
</template>

<script>
import DateSearch from '@/components/search/terms/DateSearch'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import 'flatpickr/dist/flatpickr.css'

Russian.rangeSeparator = ' - '

export default {
  name: 'DateRangeInput',
  components: { flatPickr },
  data() {
    return {
      rangeConfig: {
        mode: 'range',
        locale: Russian,
        dateFormat: this.format || 'Y-m-d',
      }
    }
  },
  methods: {
  },
  props: {
    searchTerm : {
      type: DateSearch,
      required: true
    },
    format: {
      type: String
    }
  }
}
</script>
