<template>
  <div class="form-group">
    <label>{{ searchTerm.getLabel() }}</label>
    <CInput
        v-model="searchTerm.value"
    >
      <template #prepend>
        <CDropdown
            :caret="false"
            :togglerText="searchTerm.termTitle(searchTerm.term)"
            color="primary"
        >
          <CDropdownItem
              :key="term"
              @click="searchTerm.selectTerm(term)"
              v-for="term in ['eq', 'sw', 'ew', 'ct']"
          >{{ searchTerm.termTitle(term) }}</CDropdownItem>
        </CDropdown>
      </template>
    </CInput>
  </div>
</template>

<script>
import LikeSearch from '@/components/search/terms/LikeSearch'

export default {
  name: 'LikeInput',
  methods: {

  },
  props: {
    searchTerm : {
      type: LikeSearch,
      required: true
    }
  }
}
</script>
