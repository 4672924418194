const dateRegexp = /(\d{4}-\d{2}-\d{2}) - (\d{4}-\d{2}-\d{2})/

class DateSearch {
    value = null

    key = null

    reg = null

    label = null

    constructor(key, label, regexp = dateRegexp) {
        this.key = key
        this.reg = regexp
        this.label = label
    }

    getLabel() {
        return this.label
    }

    getDescription() {
        return this.label + ': ' + this.value
    }

    notEmpty() {
        return this.value && (this.value.length > 0)
    }

    getSearchValue() {
        let dates = String(this.value)
            .match(this.reg)
        return [
            dates[ 1 ],
            dates[ 2 ]
        ]
    }

    getSearchKey() {
        return this.key
    }

    clear() {
        this.value = null
    }
}

export default DateSearch